<template>
	<div class="admin" :style="{ minHeight: minHeight + 'px' }">
		<van-nav-bar
			title="疫境求真"
			class="qjc-agent-navbar"
		>
			<span @click="exit" slot="right" class="exit qjc-fts-28">退出</span>
		</van-nav-bar>
		
		<div @click="$router.push('/')" class="card qjc-c-fff">
			<img src="../../../assets/images/agent_admin_title.png" alt="title">
		</div>
		
		<div class="detail qjc-bg-fff" ref="box">
			<div @click="$router.push('/agent/admin/openList')" class="title qjc-fts-34">
				<span>账号开通</span>
				<img class="title-arrow" src="../../../../../xgtj/assets/img/arrow_down.png" alt="箭头">
			</div>
			
			<div class="form">
				<van-field
					v-model="mobileShow"
					label="手机号"
					placeholder="请输入手机号"
					@input="mobileFormat"
					@blur="mobileVerify"
				></van-field>
				
				<van-field
					v-model="name"
					label="姓名"
					placeholder="请输入姓名"
				></van-field>
				
				<van-field
					v-model="sex"
					label="性别"
				>
					<van-radio-group 
						v-model="sex" 
						class="radios" 
						icon-size="0.36rem"
						checked-color="#6883FB"
						slot="input"
					>
					  <van-radio name="1">男</van-radio>
					  <van-radio name="2">女</van-radio>
					</van-radio-group>
				</van-field>
				
				<van-field
					v-model="birthShow"
					label="出生日期"
					placeholder="请选择出生日期"
					@click="selBirth = true"
				></van-field>
				
				<van-field
					v-model="type"
					label="类别"
				>
					<van-radio-group
						v-model="type" 
						class="radios" 
						icon-size="0.36rem"
						checked-color="#6883FB"
						slot="input"
					>
					  <van-radio name="2">KOL</van-radio>
					  <van-radio name="3">机构</van-radio>
					</van-radio-group>
				</van-field>
				
				<van-button @click="openAccount" class="open qjc-bg-primary qjc-c-fff qjc-fts-32 qjc-ftw-n">
					<span>开通</span>
					<img src="../../../assets/images/agent_login_arrow.png" alt="">
				</van-button>
				
			</div>
			
		</div>
		
		<div class="agent qjc-bg-fff">
			<div class="title qjc-fts-34">
				<span>代理人人数</span>
			</div>
			
			<!-- 代理人、KOL -->
			<div class="list qjc-clearfix qjc-texta-l">
			  <dl>
				  <dt>KOL(人)</dt>
				  <dd>{{ KOL }}</dd>
			  </dl>
			  <dl>
				  <dt>机构(个)</dt>
				  <dd>{{ organ }}</dd>
			  </dl>
			  <dl>
				  <dt>代理人人数(人)</dt>
				  <dd>{{ agent }}</dd>
			  </dl>
			  <dl>
				  <dt>机构员工人数(人)</dt>
				  <dd>{{ staff }}</dd>
			  </dl>
			</div>
			
		</div>
		
		<!-- 出生日期选择 -->
		<van-popup
			v-model="selBirth"
			position="bottom"
		>
			<van-datetime-picker
			  v-model="defaultBirth"
			  type="date"
			  title="选择年月日"
			  :min-date="minDate"
			  :max-date="maxDate"
			  @cancel="selBirth = false"
			  @confirm="checkBirth"
			/>
		</van-popup>
		
		<!-- 开通提示框 -->
		<van-popup
			v-model="open.alert"
			class="open-alert"
		>
			<h3>開通{{ open.code == 200?'成功':'失敗' }}</h3>
			
			<!-- 開通成功 -->
			<div v-if="open.code == 200" class="suc">
				<ul>
					<li>賬號：{{ open.account }}</li>
					<li>密碼：{{ open.password }}</li>
					<li>手機號：{{ open.mobile }}</li>
				</ul>
				
				<div id="code">
					<vue-qr 
						:logoSrc="require('@/assets/images/agent_miyinlogo.png')" 
						:text="open.loginCode"
						:size="500"
						:margin="0"
						:logoScale=".25"
						:correctLevel="3"
					></vue-qr>
				</div>
				
				<p class="qjc-texta-c">掃描圖中二維碼即可登陸</p>
				
			</div>
			
			<!-- 開通失敗 -->
			<div v-else class="err">
				{{ open.errMsg }}
			</div>
			
			<van-button @click="open.alert = false" class="open-alert-btn qjc-bg-primary qjc-c-fff qjc-fts-32 qjc-ftw-n">
				<span class="qjc-vertical-m">確定</span>
				<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
			</van-button>
			
		</van-popup>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, Toast, RadioGroup, Radio, DatetimePicker, Popup, Button } from 'vant'
	Vue.use(NavBar)
	   .use(Field)
	   .use(Toast)
	   .use(RadioGroup)
	   .use(Radio)
	   .use(DatetimePicker)
	   .use(Popup)
	   .use(Button);
	   
	import { isPhone, isHKPhone } from '@/js/utils'
	import { backAgentInfo, backAgentOpen } from '@/js/axios'
	
	import vueQr from 'vue-qr'
	
	export default{
		name: 'agentAdmin',
		data() {
			return {
				minHeight: window.innerHeight,// 最低高度为全屏高度
				
				mobileShow: '',// 展示手机号(带空格,提交时使用mobile)
				name: '',
				sex: '',
				birth: '',
				type: '',
				
				selBirth: false,
				defaultBirth: '',// 日期默认展示
				minDate: '',
				maxDate: '',
				
				// 开通弹窗
				open: {
					alert: false,
					code: '',// 开通返回码(200成功或失败)
					errMsg: '',// 开通失败返回提示消息
					account: '',
					password: '',
					mobile: '',
					loginCode: ''// 开通成功登陆二维码
				},
				
				// 代理人人数
				KOL: 0,// KOL
				organ: 0,// 机构
				agent: 0,// 代理人人数
				staff: 0// 机构员工人数
				
			}
		},
		components: {
			vueQr
		},
		computed: {
			mobile() {
				return this.mobileShow.replace(/\s/g, '');
			},
			
			birthShow() {
				if(this.birth){
					var birth = new Date(this.birth*1000),
						y = birth.getFullYear(),
						m = birth.getMonth()+1,
						d = birth.getDate();
					return y + '/' + m + '/' + d;
				}else{
					return '';
				}
			}
		},
		mounted() {
			// 日期
			// 默认20年前
			this.defaultBirth = this.agoDate(-20);
			// 最小200年前
			this.minDate = this.agoDate(-200);
			// 最大当前
			this.maxDate = this.agoDate(0);
			
			// 获取代理人人数信息
			this.agentInfo();
			
		},
		methods: {
			// 开通
			openAccount() {
				if(this.mobileVerify()){
					if(!this.name){
						Toast.fail('请输入姓名');
					}else if(!this.sex){
						Toast.fail('请选择性别');
					}else if(!this.birth){
						Toast.fail('请选择出生日期');
					}else if(!this.type){
						Toast.fail('请选择类别');
					}else{
						let data = {
							mobile: this.mobile,
							name: this.name,
							sex: this.sex,
							birth: this.birth,
							type: this.type
						}
						
						backAgentOpen(data).then(res => {
							// console.log(res)
							this.open.code = res.code;
							this.open.alert = true;
							if(res.code == 200){
								this.open.account = res.data.username;
								this.open.password = res.data.password;
								this.open.mobile = res.data.mobile;
								
								this.open.loginCode = location.origin + '/' + this.$router.resolve({
									path: '/agent/login',
									query: {
										type: 2,// 账号登录
										account: this.open.account,
										password: this.open.password
									}
								}).href
								
							}else{
								this.open.errMsg = res.msg;
							}
						});
						
					}
				}
				
			},
			
			agentInfo() {
				backAgentInfo().then(res => {
					// console.log(res)
					if(res.code == 200){
						this.KOL = res.data.kol;
						this.organ = res.data.org;
						this.agent = res.data.agent;
						this.staff = res.data.employ;
						
					}else{
						Toast.fail(res.msg);
					}
					
				});
			},
			
			mobileFormat() {
				// 只能输入数字
				let mobileText = this.mobileShow.replace(/\D/g, '').toString();
				
				// 从后往前,四字一空格
				for(var i=0,phone=[];i < Math.ceil(mobileText.length/4);i++){
					if(i == 0){
						phone.push(mobileText.slice(-4*(i+1))); 
					}else{
						phone.push(mobileText.slice(-4*(i+1), -4*i)); 
					}
				}
				
				this.mobileShow = phone.reverse().join(' ');
				
			},
			
			mobileVerify() {
				if(isPhone(this.mobile) || isHKPhone(this.mobile)){
					return true;
				}else{
					Toast.fail('手机号格式有误');
					return false;
				}
			},
			
			// 退出
			exit() {
				this.$store.dispatch('agentExit').then(() => {
					Toast.success('已退出');
					this.$router.push('/agent/login');
				});
			},
			
			checkBirth(val) {
				this.birth = val.getTime()/1000;
				this.selBirth = false;
			},
			
			// 距离当前日期多少年 
			agoDate(year) {
				var now = new Date(),
					y = now.getFullYear(),
					m = now.getMonth()+1,
					d = now.getDate();
				
				return new Date((y+year)+'/'+m+'/'+d);
			}
		}
	}
</script>

<style scoped>
	.form >>> .van-field__label{
		color: #8C9699;
		text-align: left;
	}
	.form >>> .van-field__control{
		text-align: right;
	}
	.radios >>> .van-radio__label{
		margin-left: 0.12rem;
	}
	.van-popup >>> .van-picker__cancel, 
	.van-popup >>> .van-picker__confirm{
		color: #6681FA;
	}
</style>
<style lang="scss" scoped>
	.admin{
		color: #3B4549;
		background-color: #EBEDF5;
		padding-bottom: 0.28rem;
		
		.van-nav-bar{
			.exit{
				color: #8C9699;
			}
		}
		
		.card{
			img{
				display: block;
				width: 100%;
				height: auto;
			}
			
		}
		
		.detail,
		.agent{
			border-radius: 0.12rem;
			margin: 0.24rem 0.24rem 0;
			
			.title{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.94rem;
				background: url(../../../assets/images/agent_earnings_people.png) no-repeat;
				background-size: 0.37rem 0.32rem;
				background-position: 0.24rem center;
				padding: 0 0.24rem 0 0.68rem;
				border-bottom: 0.01rem solid #E1E3EB;
				
				.title-arrow{
					width: 0.26rem;
					height: 0.15rem;
					transform: rotateZ(-90deg);
				}
			}
			
			dl{
				padding-left: 0.38rem;
				
				dt{
					color: #8C9699;
					font-size: 0.26rem;
					line-height: 0.26rem;
				}
				dd{
					font-size: 0.4rem;
					line-height: 0.4rem;
					margin-top: 0.28rem;
				}
				
				&.total{
					border-bottom: 0.01rem solid #E1E3EB;
					padding: 0.37rem 0 0.31rem;
					
					dd{
						font-size: 0.48rem;
						line-height: 0.48rem;
					}
				}
			}
			
			.list{
				padding-top: 0.47rem;
				
				dl{
					width: 50%;
					height: 1.58rem;
					float: left;
				}
			}
			
		}
		
		.detail{
			.form{
				padding: 0.35rem 0.24rem 0.48rem;
				
				.van-field{
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: 0.8rem;
					font-size: 0.3rem;
					border: 0.01rem solid #E1E3EB;
					border-radius: 0.08rem;
					margin-bottom: 0.2rem;
					padding: 0 0.24rem;
					
					.radios{
						display: flex;
						justify-content: flex-end;
						align-items: center;
						
						.van-radio{
							margin-left: 0.56rem;
						}
					}
				}
				
			}
		}
			
		.agent{
			.title{
				background-size: 0.37rem 0.32rem;
			}
		}
		
		.open,
		.open-alert-btn{
			display: block;
			width: 3.24rem;
			height: 0.8rem;
			line-height: 1;
			border-radius: 0.08rem;
			margin: 0.48rem auto 0;
			padding: 0 0 0 0.2rem;
			
			&>span{
				display: flex;
				justify-content: center;
				align-items: center;
			}
			
			img{
				width: 0.59rem;
				height: auto;
				margin: -0.06rem 0 0 0.11rem;
			}
		}
		
		.open-alert{
			width: 5.44rem;
			font-size: 0.3rem;
			text-align: left;
			padding: 0.44rem 0 0.48rem;
			border-radius: 0.08rem;
			
			h3{
				font-size: 0.34rem;
				line-height: 0.34rem;
				font-weight: 500;
				text-align: center;
				padding-bottom: 0.4rem;
			}
			
			.suc{
				ul{
					padding: 0.12rem 0 0.24rem;
					
					li{
						line-height: 0.3rem;
						padding: 0 0.56rem 0.32rem;
					}
				}
				
				#code{
					width: 3.6rem;
					height: 3.6rem;
					margin: 0 auto;
					
					img{
						width: 100%;
						height: 100%;
					}
				}
				
				p{
					color: #8C9699;
					font-size: 0.26rem;
					line-height: 0.26rem;
					margin-top: 0.2rem;
				}
			}
			
			.err{
				line-height: 0.38rem;
				padding: 0 0.31rem;
				
				&+.van-button{
					margin-top: 0.36rem;
				}
			}
			
		}
		
	}
</style>
